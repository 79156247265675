var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "col-md-" + _vm.cols },
    [
      _c(
        "b-form-group",
        {
          staticClass: "mb-3 form-group-select",
          attrs: { label: _vm.label, "label-for": _vm.id }
        },
        [
          _c(
            "multiselect",
            {
              ref: "multiselect",
              class: "" + (_vm.isDisplayErrors() ? "is-invalid" : ""),
              attrs: {
                value: _vm.selectedValue,
                id: _vm.id,
                name: _vm.ifnull(_vm.name, _vm.id),
                label: _vm.labelField,
                "track-by": _vm.valueField,
                placeholder: _vm.ifnull(
                  _vm.placeholder,
                  "Digite para pesquisar"
                ),
                "select-label": _vm.showSelectHelper
                  ? "Pressione enter para selecionar"
                  : "",
                "selected-label": "Selecionado",
                "deselect-label": _vm.showSelectHelper
                  ? "Pressione enter para remover"
                  : "",
                "open-direction": "bottom",
                options: _vm.options,
                multiple: _vm.multiple,
                searchable: true,
                loading: _vm.isLoading,
                "internal-search": _vm.searchAllOptions,
                "clear-on-select": true,
                "close-on-select": !_vm.multiple,
                "preserve-search": false,
                "max-height": 450,
                "show-no-results": false,
                "hide-selected": _vm.hideSelected,
                disabled: _vm.disabled
              },
              on: {
                "search-change": _vm.asyncFind,
                select: _vm.change,
                remove: function($event) {
                  return _vm.change(null)
                },
                input: _vm.changeMulti
              },
              scopedSlots: _vm._u([
                {
                  key: "option",
                  fn: function(props) {
                    return [
                      _c(
                        "div",
                        {
                          style: {
                            "text-decoration":
                              props.option.status === false
                                ? "line-through"
                                : "none"
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(props.option[_vm.labelField]) + " "
                          )
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "tag",
                  fn: function(props) {
                    return [
                      _c("span", { staticClass: "multiselect__tag" }, [
                        _c(
                          "span",
                          {
                            style: {
                              "text-decoration":
                                props.option.status === false
                                  ? "line-through"
                                  : "none"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(props.option[_vm.labelField]) + " "
                            )
                          ]
                        ),
                        _c("i", {
                          staticClass: "multiselect__tag-icon",
                          attrs: { "aria-hidden": "true" },
                          on: {
                            click: function($event) {
                              return props.remove(props.option)
                            },
                            keyup: [
                              function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return props.remove(props.option)
                              },
                              function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "delete",
                                    [8, 46],
                                    $event.key,
                                    ["Backspace", "Delete", "Del"]
                                  )
                                ) {
                                  return null
                                }
                                return props.remove(props.option)
                              }
                            ]
                          }
                        })
                      ])
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.selectedValue,
                callback: function($$v) {
                  _vm.selectedValue = $$v
                },
                expression: "selectedValue"
              }
            },
            [
              _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
                _vm._v(_vm._s(_vm.noResults))
              ]),
              _c("span", { attrs: { slot: "noOptions" }, slot: "noOptions" }, [
                _vm._v("Digite para pesquisar")
              ])
            ]
          ),
          _vm.isDisplayErrors()
            ? _c("span", { staticClass: "text-danger" }, [
                _vm._v(_vm._s(_vm.modelErrors.first(_vm.name)))
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }