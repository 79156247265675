<template>
  <div :class="`col-md-${cols}`">
    <b-form-group
      :label="label"
      :label-for="id"
      class="mb-3 form-group-select"
    >
      <multiselect 
        ref="multiselect"
        v-model="selectedValue"
        :value=selectedValue
        :id="id"
        :name="ifnull(name, id)"
        :label="labelField" 
        :track-by="valueField" 
        :placeholder="ifnull(placeholder, 'Digite para pesquisar')" 
        :select-label="showSelectHelper ? 'Pressione enter para selecionar' : ''"
        selected-label="Selecionado"
        :deselect-label="showSelectHelper ? 'Pressione enter para remover' : ''"
        open-direction="bottom" 
        :options="options" 
        :multiple="multiple" 
        :searchable="true" 
        :loading="isLoading" 
        :internal-search="searchAllOptions" 
        :clear-on-select="true" 
        :close-on-select="!multiple" 
        :preserve-search="false"
        :max-height="450" 
        :show-no-results="false" 
        :hide-selected="hideSelected" 
        :disabled="disabled"
        :class="`${isDisplayErrors() ? 'is-invalid' : ''}`"

        @search-change="asyncFind"
        @select="change"
        @remove="change(null)"
        @input="changeMulti"
      >

        <span slot="noResult">{{ noResults }}</span>

        <span slot="noOptions">Digite para pesquisar</span>

        <template slot="option" slot-scope="props">
          <div :style="{ 'text-decoration': props.option.status === false ? 'line-through' : 'none' }">
            {{ props.option[labelField] }}
          </div>
        </template>

        <template slot="tag" slot-scope="props">
          <span class="multiselect__tag">
            <span :style="{ 'text-decoration': props.option.status === false ? 'line-through' : 'none' }">
              {{ props.option[labelField] }}
            </span>
            <i
              aria-hidden="true"
              class="multiselect__tag-icon"
              @click="props.remove(props.option)"
              v-on:keyup.enter="props.remove(props.option)"
              v-on:keyup.delete="props.remove(props.option)"
            ></i>
          </span>
        </template>

      </multiselect>

      <span class="text-danger" v-if="isDisplayErrors()">{{ modelErrors.first(name) }}</span>
    </b-form-group>
  </div>
</template>

<style scoped>
.form-group-select {
  margin-bottom: 0px !important;
}
</style>

<script>
import { ifnull } from '../../utils/mixins.string';
import Multiselect from "vue-multiselect";

export default {
  name: "FormSelectSearch",
  components: {
    Multiselect
  },
  data() {
    return {
        ifnull,
        isLoading: false,
        options: [],
        selectedValue: this.value
    };
  },
  $_veeValidate: {
    value() {
      return this.selectedValue;
    },
    name() {
      return this.name;
    }
  },
  props: {
    cols: {
        type: Number,
        required: false,
        default: 12
    },
    label: {
        type: String
    },
    name: {
        type: String,
        required: true
    },
    id: {
        type: String
    },
    disabled: {
        type: Boolean,
        default: false
    },
    multiple: {
        type: Boolean,
        default: false
    },
    displayErrors: {
        type: Boolean,
        default: true
    },
    modelErrors: {
        type: Object
    },
    labelField: {
      type: String,
      default: 'label'
    },
    valueField: {
      type: String,
      default: 'id'
    },
    placeholder: {
      type: String
    },
    noResults: {
      type: String,
      default: 'Sem resultados para a pesquisa...'
    },
    searchAllOptions: {
      type: Boolean,
      default: false
    },
    searchFunction: {
      type: Function,
      required: true
    },
    removeOptionsOnSelect: {
      type: Boolean,
      default: true
    },
    hideSelected: {
      type: Boolean,
      default: true
    },
    showSelectHelper: {
      type: Boolean,
      default: false
    },
    minLength: {
      type: Number,
      default: 3
    },
    meta: {
      type: Object || String || Number,
      default: () => {}
    },
  },
  mounted() {
    if (this.searchAllOptions) {
      this.asyncFind('', true);
    }
  },
  methods: {
    asyncFind(query, force) {
      if ((query != '' && query.length >= this.minLength && !this.searchAllOptions) || force) {
        this.searchFunction(query).then(({ data }) => {
          if (data) {
            // eslint-disable-next-line no-prototype-builtins
            if (data.hasOwnProperty('resultado')) {
              this.options = data.resultado
            } else {
              this.options = data
            }
          } else {
            this.options = []
          }
        });
      } else if (!this.searchAllOptions) {
        this.options = []
      }
    },
    change(value) {
      if (!this.multiple) {
        this.$emit('select', value, this.meta)
        if (this.removeOptionsOnSelect) this.options = []
        this.selectedValue = null
      }
    },
    changeMulti(value) {
      if (this.multiple) {
        this.$emit('select', value, this.meta)
      }
    },
    setInitialValue(value, flagStopLoop) {
      let found = null;
      for (let i = 0, m = this.options.length; i < m; i++) {
        if (this.options[i][this.valueField] == value) {
          found = this.options[i];
          break;
        }
      }

      if (found) {
        this.selectedValue = found;
      } else if (!flagStopLoop) {
        if (typeof value == 'object' && value) {
          this.options.push(value);
          this.selectedValue = value;
        } else {
          let arrValue = [value];
          if (Array.isArray(value)) {
            arrValue = value;
          }

          this.searchFunction({ ids: arrValue }).then(({ data }) => {
            if (data) {
              // eslint-disable-next-line no-prototype-builtins
              if (data.hasOwnProperty('resultado')) {
                this.options = data.resultado
              } else {
                this.options = data
              }
            } else {
              this.options = []
            }

            this.setInitialValue(value, true);
          });
        }
      }
    },
    setOptions(options) {
      this.options = options
    },
    isDisplayErrors() {
      return this.modelErrors && this.modelErrors.has(this.name) && (this.displayErrors == null || this.displayErrors);
    },
    clean() {
      this.setOptions([]);
      this.setInitialValue([]);
    }
  },
};
</script>
